/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";

const DonationForSmsfIndiaGeneralFundKanhaBuildingOfflineMobile = () => {
  const pageContent =
    '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="2"' +
    'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
    'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
    'colorhighlight="#efeffe" titletext="Heartfulness SMSF India – General Fund offline" />';
  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("smsf-india-general-fund-offline", event),
    []
  );
  return (
    <DonationLayout
      seoData={{
        title:
          "Donation for SMSF India - General Fund - Kanha Building offline - [Mobile] - Donations",
      }}
    >
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box style={{ color: "#2d3550" }}>
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "1.5em",
                fontWeight: "600",
                marginTop: "40px",
              }}
            >
              Donation for SMSF India - General Fund - Kanha Building Offline
            </h1>
          </div>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent}
          />
        </Box>
      </Grid>
    </DonationLayout>
  );
};

export default DonationForSmsfIndiaGeneralFundKanhaBuildingOfflineMobile;
